import React, { useState } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import GradientIcon from './GradientIcon'; 
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import './fisesystem.css'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import GradientIcon1 from './grad-icon'
import { Button } from '@mui/material';
import CommonButtonWithTheme from '../../components/common/CommonButton/CommonButtonWithTheme';
import CommonButton from '../../components/common/CommonButton/CommonButton';


import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import potb from '../images/potb.png'
import invtb from '../images/invtb.jpeg'
import judtb from '../images/judtb.png'
import ButtonGroup from '@mui/material/ButtonGroup';
import { Icon } from '@mui/material';

// const images = [
//   { id: 1, src: 'image2', doc_types: 'Purchase Order', document_name: 'TR812736', tags: ['Tag 2', 'Tag 5'], uploadDate: '2023-05-20', type: 'JPEG' },
//   { id: 2, src: 'image2', doc_types: 'Purchase Order', document_name: 'TR8127323', tags: ['Tag 2', 'Tag 5'], uploadDate: '2023-05-20', type: 'JPEG' },
//   { id: 3, src: 'image2', doc_types: 'Invoice', document_name: 'IN453', tags: ['Tag 2', 'Tag 5'], uploadDate: '2023-05-20', type: 'JPEG' },
//   { id: 4, src: 'image2', doc_types: 'Legal', document_name: 'L1', tags: ['Tag 2', 'Tag 5'], uploadDate: '2023-05-20', type: 'JPEG' },
//   { id: 5, src: 'image2', doc_types: 'Purchase Order', document_name: 'TR812736', tags: ['Tag 1', 'Tag 5'], uploadDate: '2023-05-20', type: 'JPEG' },
//   { id: 6, src: 'image2', doc_types: 'Invoice', document_name: 'IN123', tags: ['Tag 1', 'Tag 5'], uploadDate: '2023-05-20', type: 'JPEG' },
//   { id: 7, src: 'image2', doc_types: 'Invoice', document_name: 'IN113', tags: ['Tag 2', 'Tag 5'], uploadDate: '2023-05-20', type: 'JPEG' },
//   { id: 8, src: 'image2', doc_types: 'Purchase Order', document_name: 'TR812316', tags: ['Tag 2', 'Tag 5'], uploadDate: '2023-05-20', type: 'JPEG' },
//   { id: 9, src: 'image2', doc_types: 'Legal', document_name: 'L2', tags: ['Tag 2', 'Tag 5'], uploadDate: '2023-05-20', type: 'JPEG' },
//   { id: 10, src: 'image2', doc_types: 'Purchase Order', document_name: 'TR811736', tags: ['Tag 2', 'Tag 5'], uploadDate: '2023-05-20', type: 'JPEG' },
//   { id: 11, src: 'image2', doc_types: 'Purchase Order', document_name: 'TR812316', tags: ['Tag 2', 'Tag 5'], uploadDate: '2023-05-20', type: 'JPEG' },
//   { id: 12, src: 'image2', doc_types: 'Legal', document_name: 'L2', tags: ['Tag 2', 'Tag 5'], uploadDate: '2023-05-20', type: 'JPEG' },
//   { id: 13, src: 'image2', doc_types: 'Purchase Order', document_name: 'TR811736', tags: ['Tag 2', 'Tag 5','Tag 9'], uploadDate: '2023-05-20', type: 'JPEG' },
// ];

// const tags = [
//   'Tag 1',
//   'Tag 2',
//   'Tag 3',
//   'Tag 4',
//   'Tag 5',
//   'Tag 6',
//   'Tag 7',
//   'Tag 8',
//   'Tag 9',
//   'Tag 10',
// ];

const organizeData = (images) => {
  const data = {};

  images.forEach(image => {
    if (!data[image.document_id]) {
      data[image.document_id] = {};
    }
    if (!data[image.document_id][image.doc_types]) {
      data[image.document_id][image.doc_types] = {};
    }
    if (!data[image.document_id][image.doc_types][image.document_name]) {
      data[image.document_id][image.doc_types][image.document_name] = [];
    }
    data[image.document_id][image.doc_types][image.document_name].push(image);
  });

  return data;
};



const Folder = ({ name, children, onDoubleClick }) => {
  const [open, setOpen] = useState(false);

  const handleDoubleClick = () => {
    setOpen(!open);
    onDoubleClick && onDoubleClick();
  };

  return (
    <div className="folder" onDoubleClick={handleDoubleClick}>
      <div className="icon-container">
        <GradientIcon IconComponent={FolderIcon} />
      </div>
      <div className="folder-name">{name}</div>
      <div className="folder-contents" style={{ display: open ? 'block' : 'none' }}>
        {children}
      </div>
    </div>
  );
};


const download_pdf = async (download_path) => {
  try {
    const image_path = download_path.image_location;
    const response = await fetch('https://archivebackend.aichemist.quadance.com/get/get_pdf', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ image_path })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch PDF');
    }

    console.log('jsonResponse', response);
    const pdfBlob = await response.blob();
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'document.pdf'; // Set the desired file name

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger the click event to initiate the download
    link.click();

    // Clean up the temporary URL and link element
    URL.revokeObjectURL(pdfUrl);
    document.body.removeChild(link);
  } catch (err) {
    console.error(err);
  }
};
const FileManager = (images_data, handlItemView) => {
  // const images=images_data
  const File = ({ file }) => (
    // <div className="file">
    //   <img src={file.src} alt={file.document_name} width="50" height="50" />
    //   <div>{file.document_name}</div>
    //   <div>{file.type}</div>
    //   <div>{file.uploadDate}</div>
    // </div>
    <div className="image-item" key={file.id}>
      {file.doc_types === 'Purchase Order' ? (
        <div className="thumbnail" style={{ backgroundImage: `url(${potb})` }}></div>
      ) : file.doc_types === 'Invoice' ? (
        <div className="thumbnail" style={{ backgroundImage: `url(${invtb})` }}></div>
      ): file.doc_types === 'Legal' ? (
        <div className="thumbnail" style={{ backgroundImage: `url(${judtb})` }}></div>
      ) : 
      <div className="thumbnail" style={{ backgroundImage: `url(${judtb})` }}></div>}
    {/* <div className="thumbnail" style={{ backgroundImage: `url(${image.src})` }}></div> */}
    <div className="image-info">
      <span>{file.document_name}</span>
      {/* <span>{image.type}</span> */}
    </div>
    <div className="image-actions">
      <ButtonGroup variant="outlined" aria-label="Basic button group" sx={{
        color: '#674f87',
        ":hover":{
          color: '#674f87',
        },
        border: '1px solid #674f87',
        borderColor: '#ffffff',
        borderRadius: '10px',
        // background: 'linear-gradient(to right, #674f87 1%, #ef798a 200%)'
      }}>
        <div >
          <CommonButtonWithTheme variant="outlined"  onClick={() => {images_data.handleItem(file)}}> 
            <Icon ><GradientIcon IconComponent={VisibilityIcon}/></Icon>
          </CommonButtonWithTheme>
        </div>
        <div >
          <CommonButtonWithTheme variant="outlined">
            <Icon><GradientIcon IconComponent={EditIcon} /></Icon>
          </CommonButtonWithTheme  >
        </div>
        <div >
          <CommonButtonWithTheme variant="outlined" onClick={() => download_pdf(file)}>
            <Icon><GradientIcon IconComponent={DownloadIcon} /></Icon>
          </CommonButtonWithTheme>
        </div>
      </ButtonGroup>
    </div>
  
  </div>
  
  );
  const [images, setImageUrlIp] = useState(images_data.images);
  const [tags, settags] = useState(images_data.tags);
  const data = organizeData(images);
  const [currentPath, setCurrentPath] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const navigateTo = (path) => {
    setCurrentPath(path);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const renderContents = (data, path = []) => {
    let filteredData = data;
  
    // Filter images based on selected tags
    if (selectedTags.length > 0) {
      filteredData = Object.keys(data).reduce((acc, id) => {
        const filteredDocTypes = {};
        Object.keys(data[id]).forEach(docType => {
          const filteredDocs = {};
          Object.keys(data[id][docType]).forEach(docName => {
            const filteredFiles = data[id][docType][docName].filter(file => {
              return selectedTags.some(tag => file.tags.includes(tag));
            });
            if (filteredFiles.length > 0) {
              filteredDocs[docName] = filteredFiles;
            }
          });
          if (Object.keys(filteredDocs).length > 0) {
            filteredDocTypes[docType] = filteredDocs;
          }
        });
        if (Object.keys(filteredDocTypes).length > 0) {
          acc[id] = filteredDocTypes;
        }
        return acc;
      }, {});
    }
  
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filteredData = Object.keys(filteredData).reduce((acc, id) => {
        const matchingFiles = [];
  
        if (id.toString().toLowerCase().includes(query)) {
          Object.keys(filteredData[id]).forEach(docType => {
            Object.keys(filteredData[id][docType]).forEach(docName => {
              matchingFiles.push(...filteredData[id][docType][docName]);
            });
          });
        }
  
        if (matchingFiles.length > 0) {
          acc[id] = { '': { '': matchingFiles } };
        }
        return acc;
      }, {});
    }
  
    // Render based on the filtered data
    if (path.length === 0) {
      return Object.keys(filteredData).map((id) => (
        <Folder
          key={id}
          name={`${id}`}
          onDoubleClick={() => navigateTo([id])}
        />
      ));
    } else if (path.length === 1) {
      const id = path[0];
      return Object.keys(filteredData[id]).map((docType) => (
        <Folder
          key={docType}
          name={docType}
          onDoubleClick={() => navigateTo([id, docType])}
        />
      ));
    } else if (path.length === 2) {
      const id = path[0];
      const docType = path[1];
      return Object.keys(filteredData[id][docType]).map((docName) => (
        <Folder
          key={docName}
          name={docName}
          onDoubleClick={() => navigateTo([id, docType, docName])}
        />
      ));
    } else if (path.length === 3) {
      const id = path[0];
      const docType = path[1];
      const docName = path[2];
      return filteredData[id][docType][docName].map((file) => (
        <File key={file.id} file={file} />
      ));
    }
  };
  const testClick = () => {
    console.log('imageUrlIp',images_data.images)
    console.log('imageUrlIp2',handlItemView)
    
  };

  const [selectedTags, setSelectedTags] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <div className="file-manager">
    
      <div className='top-menu'>
        <div className='BackBtn'>
          {currentPath.length > 0 && (
            <button onClick={() => setCurrentPath(currentPath.slice(0, -1))}>⬅️Back</button>
          )}
        </div>
        {currentPath.length === 0 && (
          <div className='search'>
            <TextField
              variant="outlined"
              placeholder="Search..."
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
        {currentPath.length === 0 && (
          <div className='tag'>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                multiple
                size='small'
                value={selectedTags}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <>
                        Tags <GradientIcon1 IconComponent={LocalOfferIcon} />
                      </>
                    );
                  }
                  return (
                    <>
                      Tags <GradientIcon1 IconComponent={LocalOfferIcon} />
                    </>
                  );
                }}
              >
                {tags.map((tag) => (
                  <MenuItem key={tag} value={tag}>
                    <Checkbox checked={selectedTags.indexOf(tag) > -1} />
                    <ListItemText primary={tag} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {/* <Button onClick={images_data.handlItemView} color='secondary' size='small'>Item View</Button> */}

        <div className='BackBtn2'>
            <button onClick={() => images_data.handlItemView()}>Item View</button>

        </div>
      </div>     
      <div className='folder-structure'>
        {renderContents(data, currentPath)}
      </div>
    </div>
  );
};

export default FileManager;
