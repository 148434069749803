

import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import CommonButton from '../../components/common/CommonButton/CommonButton';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import { useNavbar } from '../../components/Navbar/NavbarContext';
import { ReactPictureAnnotation, IShapeStyle } from 'react-picture-annotation';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import FormComponent from './edit-form'
import EF from './ef2'

// import { Document, Page, pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


function DisplayDoc(imageUrlIpBase) {

  const [imageUrlIp, setImageUrlIp] = useState(imageUrlIpBase);
  const [count, setCount] = useState(0);
  const { setCollapsed } = useNavbar();
  setCollapsed(true);
  const [value, setValue] = useState('1');
  const [pdf_list, setPdfList] = useState([1,8,19]);
  const [word_list, setWordList] = useState([190]);
  const [imageSrc] = useState('../images/007896060_00008.jpg');
  const [pageSize, setPageSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const ocrData = imageUrlIp.all_ocr_data;
  const [annotations, setAnnotations] = useState([]);
  const [selectedAnnotationId, setSelectedAnnotationId] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onResize = () => {
    setPageSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const handleSelect = (selectedId) => {
    setCount(count + 1);
    setSelectedAnnotationId(selectedId);
  };
  const handleExit = () => {
    console.log('Rectangle drawn-2 exit');
  };
  const [pdfData, setPdfData] = useState(null);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchPdf = async () => {
  //     try {
  //       const image_path='static/1.pdf'
  //       const response = await fetch('https://archivebackend.aichemist.quadance.com/get/get_pdf');

  //       if (!response.ok) {
  //         throw new Error('Failed to fetch PDF');
  //       }

  //       console.log('jsonResponse',response);
  //       const pdfBlob = await response.blob();
  //       const pdfDataUrl = URL.createObjectURL(pdfBlob);
  //       setPdfData(pdfDataUrl);
  //     } catch (err) {
  //       setError('Failed to fetch PDF');
  //       console.error(err);
  //     }
  //   };


  //   fetchPdf();
  // }, []);
  useEffect(() => {
    const fetchPdf = async () => {
      try {
        console.log('jsonResponse-docs-in');
        const image_path = imageUrlIp.item.image_location;
        const response = await fetch('https://archivebackend.aichemist.quadance.com/get/get_pdf', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ image_path })
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch PDF');
        }
  
        console.log('jsonResponse-docs', response);
        const pdfBlob = await response.blob();
        const pdfDataUrl = URL.createObjectURL(pdfBlob);
        setPdfData(pdfDataUrl);
      } catch (err) {
        setError('Failed to fetch PDF');
        console.error(err);
      }
    };
  
    fetchPdf();
  }, []);


  const scrollContainerRef = useRef(null);
  const handleDelete = (annotationId) => {
    setAnnotations(annotations.filter((annotation) => annotation.id !== annotationId));
    setSelectedAnnotationId(null); // Clear selection after deletion
  };

  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [currentColumnIndex, setCurrentColumnIndex] = useState(null);
  const defaultShapeStyle: IShapeStyle = {
    padding: 5,
    fontSize: 12, 
    fontColor: "#212529",
    fontBackground: "#432F60",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",

    lineWidth: 2, 
    shapeBackground: "hsla(210, 16%, 93%, 0.2)", 
    shapeStrokeStyle: "#BF40BF", 
    shadowBlur: 10, 
    shapeShadowStyle: "hsla(210, 9%, 31%, 0.35)", 
    transformerBackground: "#5c7cfa",
    transformerSize: 10
  };
  
  const tabBackground = (index) => {
    return value === index ? '#ffffff' : 'inherit';
  };

  const longText = imageUrlIp.all_context; 

 

  const testClick = () => {
    console.log('imageUrlIp',pdfData.numPages)
    
  };



  return (
    <GridWrapper >
      <Grid container spacing={2} sx={{ paddingTop: 5 }}>
        <Grid item xs={5} sx={{ background:  '#ffffff'}}>
          {/* <Button onClick={testClick}>TESTClick</Button> */}
          <EF item={imageUrlIp.item}  handleItemback={imageUrlIp.handleItemback} />
        </Grid>
        <Grid item xs={6.6} sx={{ background: '#ffffff' }}>
            <iframe
          src={pdfData} // URL of the PDF
          width="100%"
          height="1000px"
          title="PDF Viewer"
          style={{ border: 'none' }}
        />
          {/* <div className="App">
            <Box sx={{ width: '100%', height: '1000px' }}>

              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '900px',
                  overflow: 'scroll',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '900px',
                  }}
                >
                  <ReactPictureAnnotation
                    id='baseimage'
                    image={imageUrlIp.imageUrlIp}
                    onSelect={handleSelect}
                    onChange={handleSave}
                    onDelete={handleDelete}
                    onExit={handleExit}
                    width={pageSize.width}
                    height={pageSize.height}
                    annotationData={annotations}
                    shape="rect"
                    annotationStyle={defaultShapeStyle}

                    inputElement={(value, onChange, onDelete) =>
                      <form>

                        <div onClick={get_ocr_text}>

                          <Button sx={{
                            color: '#ffffff',
                            border: '1px solid #674f87',
                            borderRadius: '10px',
                            background: 'linear-gradient(to right, #674f87 1%, #ef798a 200%)',
                            '&:hover': {
                              background: 'linear-gradient(to top right, #ef798a 1%, #674f87)',
                              border: '1px solid #674f87',
                              color: '#ffffff'
                            }
                          }} variant="contained" endIcon={<SendIcon />}>

                          </Button>
                        </div>

                      </form>
                    }
                  />
                </div>
              </div>
            </Box>
          </div> */}
        </Grid>

      </Grid></GridWrapper>

  );
}

export default DisplayDoc;
