import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Authentication from './pages/Authentication/Authentication';
import Help from './pages/Help/Help';
import AccountInfo from './pages/AccountInfo/AccountInfo';
import { ThemeProvider } from '@mui/material/styles';
import { dashboardTheme } from './dashboardTheme';
import { NavbarProvider } from './components/Navbar/NavbarContext';
import { AssistProvider } from './components/ai-assist/AssistContext';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Dashboard from './pages/AR-Dashboard/Dashboard';
import DataTable from './pages/Tags/datatable'
ReactDOM.render(
  <React.StrictMode>
    <NavbarProvider>
      <AssistProvider>
        <ThemeProvider theme={dashboardTheme}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<App />}>
                <Route path="dashboard" element={<AccountInfo />} />
                <Route path="documents" element={<Dashboard />} />
                <Route path="authentication" element={<Authentication />} />
                <Route path="home" element={<Home />} />
                <Route path="help" element={<Help />} />
                <Route path="account-info" element={<AccountInfo />} />
                <Route path="tags" element={<DataTable />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </AssistProvider>
    </NavbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);