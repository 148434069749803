import React from 'react';

const GradientText = ({ text }) => {
  return (
    <svg
      style={{
        width: '100%',
        height: '120px',
      }}
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="1%" stopColor="#674f87" />
          <stop offset="200%" stopColor="#ef798a" />
        </linearGradient>
      </defs>
      <text
        x="0"
        y="25"
        dy=".30em"
        fontSize="20"
        fontFamily="Roboto, sans-serif"
        fill="url(#gradient)"
      >
        {text}
      </text>
    </svg>
  );
};

export default GradientText;
