import React from 'react';

const GradientIcon = ({ IconComponent }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      style={{
        width: '24px',
        height: '24px',
      }}
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="1%" stopColor="#674f87" />
          <stop offset="200%" stopColor="#ef798a" />
        </linearGradient>
      </defs>
      <IconComponent
        style={{
          fill: 'url(#gradient)',
        }}
      />
    </svg>
  );
};

export default GradientIcon;

