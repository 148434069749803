
import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Slider from '@mui/material/Slider';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';

import GradButton from '../../components/Grad_Buttons/grad-icon-buttton';
import DownloadIcon from '@mui/icons-material/Download';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import HubIcon from '@mui/icons-material/Hub';

import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import HeadingTagsPopover from './popover-tag'
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SummarizeIcon from '@mui/icons-material/Summarize';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NativeSelect from '@mui/material/NativeSelect';


export default function InputAdornments({item,handleItemback,}) {
    const [showPassword, setShowPassword] = React.useState(false);
    const [tags, setTags] = useState([]);
    const [newName, setNewName] = useState('');
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [value, setValue] = React.useState([0, 1000]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleDownload = () => {
        console.log(item)
        console.log('handleItemback',handleItemback)
        download_pdf()
    };
    const updateTagsInItem = (item, selectedTags) => {
        return {
          ...item,
          tags: selectedTags
        };
      };
      const download_pdf = async () => {
        try {
          const image_path = item.image_location;
          const response = await fetch('https://archivebackend.aichemist.quadance.com/get/get_pdf', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ image_path })
          });
      
          if (!response.ok) {
            throw new Error('Failed to fetch PDF');
          }
      
          console.log('jsonResponse', response);
          const pdfBlob = await response.blob();
          const pdfUrl = URL.createObjectURL(pdfBlob);
      
          // Create a temporary link element
          const link = document.createElement('a');
          link.href = pdfUrl;
          link.download = 'document.pdf'; // Set the desired file name
      
          // Append the link to the document
          document.body.appendChild(link);
      
          // Trigger the click event to initiate the download
          link.click();
      
          // Clean up the temporary URL and link element
          URL.revokeObjectURL(pdfUrl);
          document.body.removeChild(link);
        } catch (err) {
          console.error(err);
        }
      };
    const update_backend = async (updatedItem) => {
        try {
          const updated_image= updatedItem;
          const response = await fetch('https://archivebackend.aichemist.quadance.com/insert/update_tags', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ updated_image })
          });
    
          if (!response.ok) {
            throw new Error('Failed to fetch PDF');
          }
    
          console.log('jsonResponse', response);
        } catch (err) {
          console.error(err);
        }
      };
    const handleDocumentSave = () => {
        const updatedItem = updateTagsInItem(item, selectedTags);
        update_backend(updatedItem)
    };
    function valuetext(value) {
        return `${value}-Page`;
    }
    const appendTag = (value) => {
        if (!tags.includes(value)) {
          setTags([...tags, value]);
        }
      };
    const [page_no, setPageNo] = useState('1');




      //Added AI assistance
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [isChatVisible, setIsChatVisible] = useState(false);
    const chatContentRef = useRef(null);
    const [inputMessage, setInputMessage] = useState('');

    const handleToggleChat = () => {
        setIsChatVisible(!isChatVisible);
      };
      const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    useEffect(() => {
        if (chatContentRef.current) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSearch = () => {
        if (message.trim() === '') return;
        const newMessages = [...messages, { from: 'user', text: message }];
        setMessages(newMessages);
        const data = {
            inputMessage: message,
            FileName: item.primary_key
        };

        // Make API call to Flask backend using fetch
        fetch('https://archivebackend.aichemist.quadance.com/ai/get_replay', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(responseData => {
            const newMessages = [
                ...messages,
                { from: 'user', text: message },
                { from: 'backend', text: responseData.response }
            ];
            setMessages(newMessages);
            setMessage('')
        })
        .catch(error => {
            console.error('There was an error!', error);
        });
    };
    
    const Summarize = () => {
        const newMessages = [...messages, { from: 'user', text: 'Summarizing...' }];
        setMessages(newMessages);

        // Use a timeout to ensure 'Summarizing...' is rendered before sending the API request
        setTimeout(() => {
            console.log('---!!',item.id)
            const defaultMsg = "This is a default message"; // Define your default message here
            const data = {
                inputMessage: 'Summarize this document',
                FileName: item.primary_key
            };

            // Make API call to Flask backend using fetch
            console.log('---!data!',data)
            fetch('https://archivebackend.aichemist.quadance.com/ai/get_summary', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then(response => response.json())
            .then(responseData => {
                console.log('--responseData-!!',responseData)
                const newMessages = [
                    ...messages,
                    { from: 'user', text: "Summarizing..." },
                    { from: 'backend', text: responseData.response }
                ];
                setMessages(newMessages);
                setMessage('')
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
        }, 0);
    };
        




    useEffect(() => {
        const fetchLength = async () => {
          try {
            const image_path = item.image_location;
      
            const response = await fetch('https://archivebackend.aichemist.quadance.com/get/get_pdf_length', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ image_path })
              });
        
              if (!response.ok) {
                throw new Error('Failed to fetch PDF');
              }
        
      
            console.log('jsonResponse',response);
            const jsondata = await response.json();
            console.log('jsondata',jsondata.num_pages)
            setPageNo(jsondata.num_pages)
          } catch (err) {
            console.error(err);
          }
        };
    
    
        fetchLength();
      }, []);
      useEffect(() => {
        const fetchLength = async () => {
          try {
            const response = await fetch('https://archivebackend.aichemist.quadance.com/get/get_tag');
      
            if (!response.ok) {
              throw new Error('Failed to fetch PDF');
            }
      
            // console.log('jsonResponse',response);
            const jsondata = await response.json();
            console.log('jsondata',jsondata.tags)
            // setImages(jsondata.images)
            setTags(jsondata.tags)
            // setDoc_types(jsondata.doc_types)
            console.log('tags',tags)
          } catch (err) {
            console.error(err);
          }
        };
    
    
        fetchLength();
      }, []);

    const [selectedTags, setSelectedTags] = useState([]);
    const handleTagsChange = (tags) => {
        setSelectedTags(tags.selectedTags);
      };
      
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        console.log("newName",newName)
        appendTag(newName);

        handleClose();
    };
    window.selectedTagList = [];
    return (
        <Box sx={{ marginTop:'20px', height: '900px',background:  `linear-gradient(to bottom, #ffffff, #eae8ed)`}}>
            <div className='DialogueBox' style={{ marginTop: '30px', marginLeft: '88%' }}>
                {/* <React.Fragment> */}
                    {/* <Button variant="contained" onClick={handleClickOpen} startIcon={<AddCircleOutlineIcon />} size='small' color='secondary' >
                        Create
                    </Button> */}
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            component: 'form',
                        }}
                    >
                        <DialogTitle>Create New Tag</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="name"
                                label="Name"
                                size="small"
                                color='secondary'

                                type="text"
                                fullWidth
                                variant="standard"
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                
                            />

                        </DialogContent>
                        <DialogActions>
                            <Button variant='outlined' size="small" color='secondary' onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant='outlined' size="small" color='secondary'  onClick={handleSave} disabled={!newName.trim()}>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                {/* </React.Fragment> */}
            </div>
            <div>
            <Typography variant="h1" component="h2" color='secondary'>
                {item.document_id}
            </Typography>

            
            </div>
            <div>
                <FormControl fullWidth sx={{ m: 7, width: '90%' }}>
                    <Grid container spacing={1} alignItems="right">
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={2.5}>
                            <GradButton header={'Download'} handleClick={handleDownload} header_icon={DownloadIcon} />
                        </Grid>
                        <Grid item xs={2.5}>
                            <GradButton header={'Save'} handleClick={handleDocumentSave} header_icon={SaveIcon} />
                        </Grid>
                        <Grid item xs={2.5}>
                            <GradButton header={'AI'} handleClick={handleToggleChat} header_icon={HubIcon} />
                        </Grid>
                        <Grid item xs={2.5}>
                            <div onClick={handleItemback}>
                            <GradButton header={'Close'}  header_icon={HighlightOffIcon} />
                            </div>
                        </Grid>
                    </Grid>
                </FormControl> 
            </div>

            <div>
                <FormControl fullWidth sx={{ m: 2, width: '90%' }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <label>Page Range</label>
                        </Grid>
                        <Grid item xs={8}>
                            <Slider
                                getAriaLabel={() => 'Temperature range'}
                                value={value}
                                size='small' color='secondary'
                                onChange={handleChange}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                min={0} 
                                max={page_no} 
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </div>
            <div>
                <FormControl fullWidth sx={{ m: 2, width: '90%' }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <label>Case Id</label>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                required
                                id="doc-no"
                                size='small'
                                color='secondary'
                                label="Case Id"
                                defaultValue= {item.document_id}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </div>
            <div>
                <FormControl fullWidth sx={{ m: 2, width: '90%' }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <label>Document Name</label>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                required
                                id="doc-no"
                                size='small'
                                color='secondary'
                                label="Document Name"
                                defaultValue= {item.document_name}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </div>
            <div>
                <FormControl fullWidth sx={{ m: 2, width: '90%' }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <label>Archive Serial Number</label>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                id="outlined-required"
                                size='small' color='secondary'
                                label="Archive Serial Number"
                                defaultValue={item.id}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </div>
            <div>
                <FormControl fullWidth sx={{ m: 2, width: '90%' }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <label>Document Type</label>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                id="outlined-required"
                                size='small' color='secondary'
                                label='Document Type'
                                defaultValue={item.doc_types}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </div>
            <div>
                <FormControl fullWidth sx={{ m: 2, width: '90%' }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <label>Tags</label>
                        </Grid>
                        <Grid item xs={3}>
                            {/* <OutlinedInput
                                id="outlined-adornment-tags"
                                type='text'
                                size='small' color='secondary'
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Icons"
                            /> */}
                            <div>
                            <HeadingTagsPopover tags={tags} default_tags={item.tags} header={'Tags'} header_icon={LocalOfferIcon}  onChangeFunction={handleTagsChange}/>
                           </div>
                           
                        </Grid>
                        <Grid item xs={3}>
                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickOpen}
                                            edge="end"
                                        >
                                            <AddIcon />
                                        </IconButton>
                        </Grid>
                    </Grid>
                </FormControl>
            </div>
            <div>
                {isChatVisible && (
                    <div className='chat-window' 
                    style={{
                        border: "2px solid #fff",
                        height: "280px",
                        width: "90%",
                        margin: "auto",
                        borderRadius: "10px", 
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                    <div className='chat-content' ref={chatContentRef} style={{ flex: 1, padding: '10px', overflowY: 'auto' }}>
                        {messages.map((msg, index) => (
                        <div 
                            key={index} 
                            style={{ 
                            textAlign: msg.from === 'user' ? 'right' : 'left', 
                            margin: '10px 0' 
                            }}
                        >
                            <span 
                            style={{ 
                                display: 'inline-block', 
                                padding: '10px', 
                                borderRadius: '10px', 
                                backgroundColor: msg.from === 'user' ? '#525659' : '#F1F0F0',
                                color: msg.from === 'user' ? '#fff' : '#000' 
                            }}
                            >
                            {msg.text}
                            </span>
                        </div>
                        ))}
                    </div>
                    <div className='fixedTags' style={{ justifyContent: "center", display: "flex", width: "100%" }}>
                        <button 
                            style={{ 
                                textAlign: 'center',
                                display: "block",
                                textDecoration:"none",
                                border:"1px solid #674F87",
                                fontSize:"13px",
                                padding:"3px 5px",
                                borderRadius:"5px",
                                color:"#9D3C5D"
                                 }} 
                            onClick={Summarize}
                            title='Summarize the select document'>Summarize Now{<SummarizeIcon style={{height:"15px", width:"20px",paddingTop:"3px"}}/>}
                        </button>
                    </div>
                    <div className='chat-input' style={{ display: 'flex', borderTop: '1px solid #ccc', padding: '4px' }}>
                        <input 
                        type='text' 
                        value={message} 
                        onChange={(e) => setMessage(e.target.value)} 
                        onKeyPress={handleKeyPress}
                        style={{ flex: 1, padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                        placeholder='Chat with the document now...'
                        />
                        <button 
                            onClick={handleSearch} 
                            style={{ 
                                marginLeft: '10px', 
                                borderRadius: '5px', 
                                border: '1px solid #674F87', 
                                color: '#9D3C5D', 
                                cursor: 'pointer', 
                                alignItems: "center",
                                backgroundColor: 'transparent'
                            }}
                            >
                            <SearchIcon style={{ width: "22px", height: "22px", color: '#674F87' }} />
                        </button>
                    </div>
                    </div>
                )}
            </div>
        </Box>
    );
}
