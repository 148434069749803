import HomeIcon from '@mui/icons-material/Home';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import GppGoodIcon from '@mui/icons-material/GppGood';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import DescriptionIcon from '@mui/icons-material/Description';
import SupportIcon from '@mui/icons-material/Support';
import HelpIcon from '@mui/icons-material/Help';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import TryIcon from '@mui/icons-material/Try';

export const mainNavbarItems = [
    {
        id: 0,
        icon: <HomeIcon />,
        label: 'Dashboard',
        route: 'dashboard',
    },
    {
        id: 1,
        icon: <DocumentScannerIcon />,
        label: 'Documents',
        route: 'documents',
    },
    {
        id: 2,
        icon: <TryIcon />,
        label: 'Tags',
        route: 'tags',
    },
    // {
    //     id: 3,
    //     icon: <ModelTrainingIcon />,
    //     label: 'Document Types',
    //     route: 'document-types',
    // }
]

export const mainProfileItems = [
    {
        id: 13,
        icon: <HelpIcon />,
        label: 'Help',
        route: 'help',
    },
    {
        id: 14,
        icon: <AccountBoxIcon />,
        label: 'Account Info',
        route: 'account-info',
    },
]