import React, { useState } from 'react';
import { Drawer, Toolbar, Divider, Box, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { mainNavbarItems, mainProfileItems } from './consts/navbarItems';
import { navbarStyles } from './styles';
import { useMediaQuery } from '@mui/material';

import { useNavbar } from './NavbarContext';
const Navbar =  () => {
    const navigate = useNavigate();
    const { collapsed, setCollapsed } = useNavbar();
    const isSmallerScreen = useMediaQuery('(max-width:600px)'); // Define your breakpoint

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    // Automatically toggle collapse based on screen size
    React.useEffect(() => {
        if (isSmallerScreen) {
            setCollapsed(true);
        } else {
            setCollapsed(false);
        }
    }, [isSmallerScreen]);

    return (
        <Drawer
          sx={{ ...navbarStyles.drawer, width: collapsed ? 57 : 220 }}
          variant="permanent"
          anchor="left"
      >
        <Toolbar />
        <Divider />
        <Box sx={{ mt: 12}}>
          <List>
            {mainNavbarItems.map((item, index) => (
              <ListItem
                  sx={navbarStyles.list}
                  button
                  key={item.id}
                  onClick={() => navigate(item.route)}
              >
                {collapsed ? (
                    <Tooltip title={item.label}>
                        <ListItemIcon
                            sx={navbarStyles.icons}
                        >
                            {item.icon}
                        </ListItemIcon>
                    </Tooltip>
                ) : (
                    <>
                        <ListItemIcon
                            sx={navbarStyles.icons}
                        >
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText 
                            sx={navbarStyles.text}
                            primary={item.label}
                        />
                    </>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ position: 'absolute', bottom: 0, mt: 12 }}>
          <List>
            {mainProfileItems.map((item, index) => (
              <ListItem
                  sx={navbarStyles.list}
                  button
                  key={item.id}
                  onClick={() => navigate(item.route)}
              >
                {collapsed ? (
                    <Tooltip title={item.label}>
                        <ListItemIcon
                            sx={navbarStyles.icons}
                        >
                            {item.icon}
                        </ListItemIcon>
                    </Tooltip>
                ) : (
                    <>
                        <ListItemIcon
                            sx={navbarStyles.icons}
                        >
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText
                            // sx={navbarStyles.text}
                            primary={item.label} 
                        />
                    </>
                )}
              </ListItem>
            ))}
          </List>
          {!isSmallerScreen && (
              <button onClick={toggleCollapse}>Toggle Collapse</button>
          )}
        </Box>
      </Drawer>
    );
};

export default Navbar;
