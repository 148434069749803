import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar/Navbar';
import Assist from './components/ai-assist/Assist';
import { Outlet } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';
import TopMenu from './components/TopMenu/TopMenu';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Cbot from './components/chatBAr/chat-bot';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';

function App() {
  window.localStorage.setItem('isLoggedIn', true);
  const [isLoggedIn, setIsLoggedIn] = useState(
    window.localStorage.getItem('isLoggedIn') || false // Check for stored login state (temporary example)
  );
  const [title, setTitle] = useState(null);
  const location = useLocation();
  window.globalVariable = 'someValue';
  var [extractionType, setExtractionType] = useState('None');
  useEffect(() => {
    const parsedTitle = location.pathname.replace(/\W/g, ' ');
    setTitle(parsedTitle);
  }, [location]);

  return (
    <div>

      {isLoggedIn && ( // Render main app content only if logged in

        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <TopMenu />
          <Navbar />
          <Box component="main" sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            paddingLeft: 10,
            height: 'calc(100vh - 50px)',
            width: 'calc(100vw - 10px)',
          }}>
            <Toolbar />
            <Outlet />
          </Box>

          {/* <Assist /> */}
        </Box>
      )}
      {!isLoggedIn && <Login />} {/* Render login form if not logged in */}

    </div>


  );
}

export default App;