import React, { useState,useEffect } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { Icon } from '@mui/material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

import GradientIcon from './grad-icon'

const HeadingTagsPopover = ({ tags, default_tags, header, header_icon, onChangeFunction }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState(default_tags);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleTagSelect = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  useEffect(() => {
    onChangeFunction({ selectedTags });
    window.selectedTagList =selectedTags;
  }, [selectedTags]);

  const filteredTags = tags.filter((tag) =>
    tag.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Button variant="outlined" size="small" onClick={handleClick} color="secondary" startIcon={<GradientIcon IconComponent={header_icon} />}>
        {header}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <TextField
          label="Search Tags"
          variant="outlined"
          value={searchTerm}
          color="secondary"
          size="small"
          onChange={handleSearchChange}
          style={{ margin: '16px' }}
        />
        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
          <List dense>
            {filteredTags.map((tag) => (
              <ListItem key={tag} button onClick={() => handleTagSelect(tag)}>
                <Checkbox
                  checked={selectedTags.includes(tag)}
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  color="secondary"
                />
                <ListItemText primary={tag} color="secondary" />
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  );
};

export default HeadingTagsPopover;